import React from 'react'

const HandsOnSupport = () => {
  return (
    <div className='m-24 text-stone-50'>
      <h1>Comming Soon</h1>
    </div>
  )
}

export default HandsOnSupport
