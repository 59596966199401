import React from "react";

import { TooltipComponent } from "@syncfusion/ej2-react-popups";
import { useEffect } from "react";
import { AiOutlineMenu } from "react-icons/ai";
import { BsChatLeft } from "react-icons/bs";
import { FiShoppingCart } from "react-icons/fi";
import { MdKeyboardArrowDown } from "react-icons/md";
import { RiNotification3Line } from "react-icons/ri";

import { Notification, UserProfile } from "components";
import avatar from "data/avatar.jpg";
import { useStateContext } from "../../contexts/ContextProvider";

const NavButton = ({ title, customFunc, icon, color, dotColor }) => (
  <TooltipComponent content={title} position="BottomCenter">
    <button
      type="button"
      onClick={() => customFunc()}
      style={{ color }}
      className="relative p-3 text-xl rounded-full hover:bg-[#292B2D]"
    >
      <span
        style={{ background: dotColor }}
        className="absolute inline-flex w-2 h-2 rounded-full right-2 top-2"
      />
      {icon}
    </button>
  </TooltipComponent>
);

const AdminvbeeHeader = () => {
  const {
    currentColor,
    activeMenu,
    setActiveMenu,
    handleClick,
    isClicked,
    setScreenSize,
    screenSize,
  } = useStateContext();

  useEffect(() => {
    const handleResize = () => setScreenSize(window.innerWidth);

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (screenSize <= 900) {
      setActiveMenu(false);
    } else {
      setActiveMenu(true);
    }
  }, [screenSize]);

  const handleActiveMenu = () => setActiveMenu(!activeMenu);

  return (
    <div
      className={`fixed right-0 bg-[#1A1C1E] border-b border-[#37383A] dark:bg-main-dark-bg w-full z-10 `}
    >
      <div
        className={`flex justify-between px-8 w-full my-2.5 relative ${
          activeMenu ? "pl-80" : "pl-8"
        }`}
      >
        <NavButton
          title="Menu"
          customFunc={handleActiveMenu}
          color={"#BDBEBE"}
          icon={<AiOutlineMenu />}
        />
        <div className="flex">
         
          <NavButton
            title="Notification"
            dotColor="#F7685B"
            customFunc={() => handleClick("notification")}
            color={"#BDBEBE"}
            icon={<RiNotification3Line />}
          />
          <TooltipComponent content="Profile" position="BottomCenter">
            <div
              className="flex items-center gap-2 p-1 rounded-lg cursor-pointer hover:bg-[#292B2D]"
              onClick={() => handleClick("userProfile")}
            >
              <img
                className="w-8 h-8 rounded-full"
                src={avatar}
                alt="user-profile"
              />
              <p>
                <span className="text-base font-semibold text-white">Hi,</span>{" "}
                <span className="ml-1 text-base font-semibold text-white">
                  Admin
                </span>
              </p>
              <MdKeyboardArrowDown className="text-sm text-white" />
            </div>
          </TooltipComponent>

          {/* {isClicked.chat && <Chat />} */}
          {isClicked.notification && <Notification />}
          {isClicked.userProfile && <UserProfile />}
        </div>
      </div>
    </div>
  );
};

export default AdminvbeeHeader;
