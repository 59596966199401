import React from 'react';

const Copilot = () => {
  return (
    <div style={{ height: '100vh', width: '100vw', marginTop: 75, padding: 0 }}>
      <iframe
        src="https://hcomb-bigagi-aca.victoriousbush-67842c2f.eastus.azurecontainerapps.io/"
        style={{ border: 'none', height: '100%', width: '100%' }}
        title="Copilot Content"
      ></iframe>
    </div>
  );
};

export default Copilot;
