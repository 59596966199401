/* eslint-disable react-hooks/rules-of-hooks */
import { useLogoutFunction } from "@propelauth/react";
import { Button } from "components";
import { useStateContext } from "contexts/ContextProvider";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "styles/Consultant.css";
import logo_circle from "../../data/logo-ico.svg";
import { TooltipComponent } from "@syncfusion/ej2-react-popups";
import { AiOutlineMenu } from "react-icons/ai";
import { RiNotification3Line } from "react-icons/ri";
import { MdKeyboardArrowDown } from "react-icons/md";

const NavButton = ({ title, customFunc, icon, color, dotColor }) => (
  <TooltipComponent content={title} position="BottomCenter">
    <button
      type="button"
      onClick={() => customFunc()}
      style={{ color }}
      className="relative p-3 text-xl rounded-full hover:bg-[#292B2D]"
    >
      <span
        style={{ background: dotColor }}
        className="absolute inline-flex w-2 h-2 rounded-full right-2 top-2"
      />
      {icon}
    </button>
  </TooltipComponent>
);

const header = ({ sidebarOpen, setSidebarOpen }) => {
  const navigate = useNavigate();
  const { 
    currentColor,
    activeHeader, 
    setActiveHeader, 
    screenSize,
    setScreenSize,
    handleClick 
  } = useStateContext();
  const logout = useLogoutFunction();

  // const [color, setColor] = useState(false);
  useEffect(() => {
    const handleResize = () => setScreenSize(window.innerWidth);

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);
  useEffect(() => {
    if (screenSize <= 900) {
      setActiveHeader(false);
    } else {
      setActiveHeader(true);
    }
  }, [screenSize]);
  
  // const handleActiveMenu = () => setActiveHeader(!activeHeader);
  const handleActiveMenu = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const handleLogout = (event) => {
    event.preventDefault();
    console.log("Logging out...");
    localStorage.removeItem("__PROPEL_AUTH_LOGGED_IN_AT");
    localStorage.removeItem("__PROPEL_AUTH_LOGGED_OUT_AT");
    localStorage.removeItem("auth");
    logout(false);
    // navigate("/");

    // window.location.href = "/";
    navigate("/");
  };

  return (
    <header
      className={"fixed right-0 bg-[#1A1C1E] border-b border-[#37383A] dark:bg-main-dark-bg w-full z-10 py-4"}
    >
      <div className={`flex justify-between px-8 w-full relative ${sidebarOpen ? "pl-8" : "pl-80"}`}>
        <div className="flex justify-between w-full row">
        <div className="flex flex-row">
            
           
              </div>
          <TooltipComponent content="Profile" position="BottomCenter">
          <div className="flex items-center gap-2 p-1 rounded-lg cursor-pointer hover:bg-[#292B2D]">
              <Button
                className="text-[#BDBEBE] bg-[#282B2F] py-2 px-4 rounded-lg"
                color="white"
                // bgColor={currentColor}
                text="Logout"
                borderRadius="10px"
                width="full"
                onClick={handleLogout}
              />
          </div> 
          </TooltipComponent>
          
        </div>
      </div>
    </header>
  );
};
export default header;
