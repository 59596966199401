/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-no-comment-textnodes */
import { useEffect, useState } from "react";
import { FaEdit } from "react-icons/fa";

import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { Link, useNavigate, useParams } from "react-router-dom";
import { TailSpin } from "react-loader-spinner";
import { deleteLesson, getSpecificModuleLessons } from "services/api";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import TopNavTrainee from "./TopNavTrainee";
import AccessCourse from "components/AccessCourse";
import Bot from "pages/Bot";

const TrainingModules = () => {
  const [lessons, setLessons] = useState([]);
  const [selectedLesson, setSelectedLesson] = useState("");
  const [deleteButtonEnabled, setDeleteButtonEnabled] = useState(false);
  const { moduleId, courseId } = useParams();
  const [moduleName,setModuleName] = useState()
  
  const navigate = useNavigate();
  const [currentModule, setCurrentModule] = useState(moduleId);
  const { selectedCourse, storedUserId } = AccessCourse({ courseId });
  const [moduleDetail, setModuleDetail] = useState(null)


  useEffect(() => {
    const fetchModuleLessons = async () => {
      try {
        const moduleLessons = await getSpecificModuleLessons(moduleId);
        setModuleName(moduleLessons?.overview?.module_name)
        setLessons(moduleLessons?.lessons);
        setModuleDetail(moduleLessons)
      } catch (error) {
        toast.error("Something Went Wrong, Please Try Again.", {
          autoClose: 3000,
        });
        
      }
    };

    fetchModuleLessons();
  }, [moduleId]);
  useEffect(() => {
    setCurrentModule(moduleId);
  }, [moduleId]);
  const handleAddLesson = () => {
    navigate(`/admin/courses/${courseId}/module/${moduleId}/new-lesson`);
  };
  const handleCheckboxChange = (lessonId) => {
    // If the same checkbox is clicked again, unselect it
    // If the same checkbox is clicked again, unselect it
    const updatedLessonId = selectedLesson === lessonId ? "" : lessonId;

    // Update the selected lesson and toggle the delete button status
    setSelectedLesson(updatedLessonId);
    setDeleteButtonEnabled(updatedLessonId !== "");
  };
  const handleDeleteLesson = async () => {
    try {
      
      const deleteddata = await deleteLesson(selectedLesson);
      
      
      setLessons((prevLessons) =>
        prevLessons.filter((lesson) => lesson._id !== selectedLesson)
      );

      // After deletion, clear selection and disable delete button
      setSelectedLesson("");
      setDeleteButtonEnabled(false);

      // Refetch lessons after deletion
      const updatedModuleLessons = await getSpecificModuleLessons(moduleId);
      setLessons(updatedModuleLessons.lessons);
    } catch (error) {
      toast.error("Something Went Wrong, Please Try Again.", {
        autoClose: 3000,
      });
      
    }
  };
  
  return (
    <>
    <ToastContainer />
     
     <TopNavTrainee  
      courseId={courseId}
      selectedCourse={selectedCourse}
      moduleName={moduleName}
      moduleId={moduleId}
      />

      {/* <div className="flex justify-end gap-4 mx-12 mt-4">
        <button
          onClick={handleDeleteLesson}
          disabled={!deleteButtonEnabled}
          className={`border rounded py-1.5 px-4 border-[#37383A] font-medium text-sm text-[#BDBEBE] uppercase ${
            !deleteButtonEnabled
              ? "bg-gray-400 cursor-not-allowed"
              : "bg-[#282B2F] hover:bg-[#282B2F]"
          }`}
        >
          Delete Lesson
        </button>
        <button
          onClick={handleAddLesson}
          className="border rounded py-1.5 px-4 border-[#37383A] font-medium text-sm text-[#BDBEBE] bg-[#282B2F] uppercase"
        >
          <AddCircleOutlineIcon sx={{ mr: 1 }} />
          Add New Lesson
        </button>
      </div> */}
      {/* {lessons?.length === 0 && (
        <div className="mx-12 mt-4 text-center">
          <p className="text-lg font-semibold text-[#BDBEBE]">
            No lessons for the selected Module
          </p>
        </div>
      )} */}
      <div className="flex flex-row">
        <div className="m-5 bg-[#1A1C1E] rounded-lg w-[60%]">
          <div className="m-5 bg-[#1A1C1E] rounded-lg">
            <div className="flex items-center justify-between header-title">
              <h4>Lessons</h4>
            </div>
            <div className="grid gap-6 p-8 overflow-x-auto 2xl:grid-cols-3 xl:grid-cols-3 lg:grid-cols-2 md:grid-cols-1">
              {lessons?.length === 0 ? (
                <div className="mx-12 mt-4 text-center">
                  <TailSpin
                    color="#FFFFFF"
                    height={20}
                    width={20}
                    style={{ margin: "10px 10px" }}
                  />{" "}
                </div>
              ) : lessons ? (
                lessons
                  .sort((a, b) => a.lesson_number - b.lesson_number)
                  .map((lesson) => (
                    <div
                      key={lesson.id}
                      className="bg-[#242728] border border-[#303234] overflow-hidden rounded-md gap-3"
                    >
                      <Link
                        to={`/training/courses/${courseId}/module/${moduleId}/lesson/${lesson.id}`}
                        style={{ textDecoration: "none" }}
                      >
                        {/* <img
                    src={lesson.image}
                    alt={lesson.name}
                    className="lg:max-w-full lg:w-96 max-sm:max-w-full max-sm:w-full sm:w-full lg:h-40 max-lg:h-40 max-sm:h-[250px] sm:h-[250px] object-cover opacity-100 transition duration-300 ease-in-out hover:opacity-20"
                  /> */}
                        {lesson.url ? (
                          lesson.url.includes("youtube.com") ||
                            lesson.url.includes("youtu.be") ? (
                            <div className="">
                              <iframe
                                title="YouTube Video"
                                width="100%"
                                height="170"
                                src={`https://www.youtube.com/embed/${lesson.url.split("v=")[1]?.split("&")[0] ||
                                  lesson.url.split("/").pop()
                                  }`}
                                frameBorder="0"
                                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                              ></iframe>
                            </div>
                          ) : (
                            <div className="">
                              <iframe
                                title="Video"
                                width="100%"
                                height="170"
                                src={lesson.url}
                                frameBorder="0"
                                allow="fullscreen"
                                allowFullScreen
                              ></iframe>
                            </div>
                          )
                        ) : (
                          <div
                            className="loading-spinner"
                            style={{
                              width: "100%",
                              height: "170px",
                              backgroundColor: "#000000",
                              animation: "loading 2s infinite linear",
                            }}
                          >
                            <TailSpin color="#c5c6c7" height={40} width={40} />
                          </div>
                        )}
                        <h2 className="flex align-middle justify-start my-2.5 text-lg font-normal px-4 text-white">
                          {lesson.title}
                          {/* <FaEdit className="absolute cursor-pointer right-2" /> */}
                        </h2>
                        <p className="px-4 opacity-50 my-2.5 text-white line-clamp-2">
                          {lesson.lesson_description}
                        </p>
                      </Link>
                      {/* <label className="absolute top-1 right-2">
                  <input
                    type="checkbox"
                    checked={lesson?.id === selectedLesson}
                    onChange={() => handleCheckboxChange(lesson?.id)}
                  />
                </label> */}
                    </div>
                  ))
              ) : (
                // Render something else if there are no courses
                <div>No Lessons available</div>
              )}
            </div>
          </div>
        </div>
        <div className="m-5 bg-[#1A1C1E] rounded-lg w-[30%]">
          <Bot type={'module'} id={moduleId} prompts={moduleDetail?.module_prompt}/>
        </div>
      </div>
    </>
  );
};

export default TrainingModules;
