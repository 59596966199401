import { Webchat, WebchatProvider, Fab, getClient, Container, Header, MessageList, Composer, ComposerInput, ComposerButton } from "@botpress/webchat";
import { buildTheme } from "@botpress/webchat-generator";
import React, { useEffect, useState } from "react";

const { theme, style } = buildTheme({
  themeName: "midnight",
  themeColor: "#5B52E7",
});

//Add your Client ID here ⬇️
const clientId = "584fa337-f41f-486a-aa6d-776d45606fe2";

const PromptButton = ({prompts, client}) =>{
  return <div>
    {prompts?.length > 0 && prompts.map((prompt, index)=>{
      return <div key={index} class="inline-flex items-center px-3 py-1 m-1 bg-[#5B52E7] text-white text-sm font-medium rounded-full cursor-pointer" onClick={async()=>{
        await client.sendMessage(prompt?.title)
        await client.listMessages()
      }}>
        <span id="chip-content">{prompt?.title}</span>
    </div> 
    })}
  </div>
}


const client = getClient({ clientId});
  
const config = {
  composerPlaceholder: "What would you like to know?",
  botName: "Pollinator",
  botAvatar: "https://1550205407.rsc.cdn77.org/logo/chatbotlogo.png",
  botDescription:
    "Hi! 👋",
};

function Bot({id, type, prompts}) {
 
  
  return (
    <div>
      <style>{style}</style>
      <WebchatProvider
        key={JSON.stringify(config)}
        theme={theme}
        //Add the configuration to the Webchat Provider ⬇️
        configuration={config}
        client={client}
        userData={{id:String(id|| 0), type}}
      >
        <Container style={{height: 800}}>
            <Header  />
            <MessageList />
            <PromptButton prompts={prompts} client={client}/>
            <Composer >
            <ComposerInput />
            <ComposerButton />
            </Composer>
        </Container>
      </WebchatProvider>
    </div>
  );
}

export default React.memo(Bot);