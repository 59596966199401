import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";

import { AddNewmodule, editModule, getModules } from "../services/api";

import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { Box, Button, Modal, TextField, Typography } from "@mui/material";
import { TailSpin } from "react-loader-spinner";
import { useReduxState } from "hooks/useReduxActions";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AccessCourse from "components/AccessCourse";
import TopNav from "components/TopNav";
import PromptsDataTable from "components/PromptsDataTable";




const Module = () => {
  const { courseId } = useParams();

  const [showAddModal, setShowAddModal] = useState(false);
  const [selectedModule, setSelectedModule] = useState('');
  const [modules, setModules] = useState([]);
  const [coreModules, setCoreModules] = useState([]);
  const [interview, setInterview] = useState([]);
  const [newModule, setNewModule] = useState({
    module_name: "",
    module_description: "",
    // youtubeLink: "",
  });
  const [courseDetails, setCourseDetails] = useState(null);

  const fetchModules = async (courseId) => {
    try {
      const response = await getModules(courseId);
      if (response) {
        const modules = response?.modules
        setCourseDetails(response)
        setModules(modules?.filter((module)=> module.type === 'ai'));
        setCoreModules(modules?.filter((module)=> module.type === 'core'))
        setInterview(modules?.filter((module)=> module.type === 'interview'))
      } else {
        toast.error("Something Went Wrong, Please Try Again.", {
          autoClose: 3000,
        });
      }
    } catch (error) {
      toast.error("Something Went Wrong, Please Try Again.", {
        autoClose: 3000,
      });
    }
  };

  const handleSavePrompts = async (updatedPrompt, editPromptsId, newPrompt) => {
    try {
      if (editPromptsId) {
        // If editQuizId is present, update the existing quiz
        const response = await editModule(courseId, {
          training_course_prompt: [updatedPrompt],
        });
        fetchModules(courseId);
      } else {
        // If editQuizId is null, add a new quiz
        const response = await AddNewmodule(courseId, {
          training_course_prompt: [newPrompt],
        });

        fetchModules(courseId);
      }
    } catch (error) {
      toast.error("Something Went Wrong, Please Try Again.", {
        autoClose: 3000,
      });
    }
  };

  useEffect(() => {
    fetchModules(courseId);
  }, [courseId]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "youtubeLink") {
      setNewModule((prevModule) => ({
        ...prevModule,
        [name]: value,
      }));
    } else {
      setNewModule((prevModule) => ({
        ...prevModule,
        [name]: value,
      }));
    }
  };

  const addNewModule = async () => {
    // const newModuleWithId = { ...newModule };
    const newModulePayload = {
      modules: [
        {
          module_name: newModule.module_name,
          module_description: newModule.module_description,
          type: selectedModule
        },
      ],
    };
    try {
      const response = await AddNewmodule(courseId, newModulePayload);
    

      setShowAddModal(false);
      setNewModule({
        module_name: "",
        module_description: "",
        youtubeLink: "",
      });
      await fetchModules(courseId);
    } catch (error) {
      toast.error("Something Went Wrong, Please Try Again.", {
        autoClose: 3000,
      });
    }
  };

  const Addmodule = (value) => {
    setShowAddModal(true);
    setSelectedModule(value)
    // const id = generateId();
    // setGenerated(id);
  };

  const { selectedCourse, storedUserId } = AccessCourse({ courseId });

  const CourseTrainerID = selectedCourse?.trainer_id;
  return (
    <>
      <ToastContainer />
      {/* <nav
        class="flex mx-12 my-4 lg:mt-20 md:mt-20 max-sm:mt-20 sm:mt-20"
        aria-label="Breadcrumb"
      >
        <ol class="inline-flex items-center space-x-1 md:space-x-2 rtl:space-x-reverse">
          <li class="inline-flex items-center">
            <Link
              to="/admin/courses"
              class="inline-flex items-center text-base font-medium text-[#BDBEBE]"
            >
              <svg
                class="w-3 h-3 me-2.5"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path d="m19.707 9.293-2-2-7-7a1 1 0 0 0-1.414 0l-7 7-2 2a1 1 0 0 0 1.414 1.414L2 10.414V18a2 2 0 0 0 2 2h3a1 1 0 0 0 1-1v-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v4a1 1 0 0 0 1 1h3a2 2 0 0 0 2-2v-7.586l.293.293a1 1 0 0 0 1.414-1.414Z" />
              </svg>
              Home
            </Link>
          </li>
          <li>
            <div class="flex items-center">
              <svg
                class="rtl:rotate-180 w-3 h-3 text-[#BDBEBE]"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 6 10"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="m1 9 4-4-4-4"
                />
              </svg>
              <svg
                class="rtl:rotate-180 w-3 h-3 text-[#BDBEBE] me-2.5"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 6 10"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="m1 9 4-4-4-4"
                />
              </svg>
              <Link
                to={`/admin/courses`}
                className="inline-flex items-center text-base font-medium text-[#BDBEBE]"
              >
                Courses
              </Link>
            </div>
          </li>
          <li>
            <div class="flex items-center">
              <svg
                class="rtl:rotate-180 w-3 h-3 text-[#BDBEBE]"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 6 10"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="m1 9 4-4-4-4"
                />
              </svg>
              <svg
                class="rtl:rotate-180 w-3 h-3 text-[#BDBEBE] me-2.5"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 6 10"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="m1 9 4-4-4-4"
                />
              </svg>
              <Link
                to={`/admin/courses/${courseId}`}
                className="inline-flex items-center text-base font-medium text-[#BDBEBE]"
              >
                {selectedCourse?.course_name}
              </Link>
            </div>
          </li>
        </ol>
      </nav> */}


<TopNav
        courseId={courseId}
        selectedCourse={selectedCourse}
      />
      

      <Modal
        open={showAddModal}
        onClose={() => setShowAddModal(false)}
        aria-labelledby="add-new-module-modal"
        aria-describedby="form-to-add-new-module"
      >
        <Box className="absolute m-auto inset-0 h-fit lg:w-1/4 md:w-2/4 sm:w-2/4 max-sm:w-4/5 bg-[#242728] text-[#BDBEBE] border-2 border-[#37383A] p-8 rounded-lg">
          <Typography
            variant="h6"
            component="h2"
            sx={{ mb: 2, fontFamily: "Poppins, sans-serif" }}
          >
            Add {selectedModule} New Module
          </Typography>

          <TextField
            label="Name"
            variant="outlined"
            name="module_name"
            value={newModule.module_name}
            onChange={handleInputChange}
            fullWidth
            sx={{
              mb: 2,
              "& input": {
                color: "#BDBEBE !important",
                fontFamily: "Poppins, sans-serif",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "#37383A !important",
              },
              "& .MuiInputLabel-outlined": {
                color: "#BDBEBE !important",
              },
            }}
          />
          <TextField
            label="Description"
            variant="outlined"
            name="module_description"
            multiline
            rows={4}
            value={newModule.module_description}
            onChange={handleInputChange}
            fullWidth
            sx={{
              mb: 2,
              "& textarea": {
                color: "#BDBEBE !important",
                fontFamily: "Poppins, sans-serif",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "#37383A !important",
              },
              "& .MuiInputLabel-outlined": {
                color: "#BDBEBE !important",
              },
            }}
          />
          {/* <TextField
            label="YouTube Link (Embed)"
            variant="outlined"
            name="youtubeLink"
            value={newModule.youtubeLink}
            onChange={handleInputChange}
            fullWidth
            sx={{
              mb: 2,
              "& input": {
                color: "#BDBEBE !important",
                fontFamily: "Poppins, sans-serif",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "#37383A !important",
              },
              "& .MuiInputLabel-outlined": {
                color: "#BDBEBE !important",
              },
            }}
          /> */}
          <div className="flex justify-end">
            <Button
              onClick={() => setShowAddModal(false)}
              sx={{
                mr: 2,
                color: "#BDBEBE",
                fontFamily: "Poppins, sans-serif",
                "&:hover": {
                  background: "#282B2F",
                },
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={addNewModule}
              sx={{
                background: "#282B2F",
                color: "#BDBEBE",
                fontFamily: "Poppins, sans-serif",
                "&:hover": {
                  background: "#282B2F",
                },
              }}
            >
              Add
            </Button>
          </div>
        </Box>
      </Modal>

    

      <div className="m-5 bg-[#1A1C1E] rounded-lg">
        <div className="flex items-center justify-between header-title">
          <h4>Core Modules</h4>
          {storedUserId === CourseTrainerID ? (
          <div className="">
            <Button
              variant="contained"
              onClick={() => Addmodule('core')}
              sx={{
                background: "#5b52e7",
                color: "#ffffff",
                fontFamily: "Poppins, sans-serif",
                "&:hover": {
                  background: "#5b52e7",
                },
              }}
            >
              <AddCircleOutlineIcon sx={{ mr: 1 }} /> Add Core Modules Module
            </Button>
          </div>
        ) : null}
        </div>


      <div className="grid gap-6 p-8 overflow-x-auto 2xl:grid-cols-5 xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2">
        {coreModules?.length === 0 ? (
          // Loader component or loading message goes here
          <div>No Core modules available</div>
        ) : coreModules ? (
          coreModules?.map((module) => (
            <div
              key={module.id}
              className="bg-[#242728] border border-[#303234] rounded-md p-4 gap-3"
            >
              <Link
                to={`/admin/courses/${courseId}/module/${module.id}`}
                style={{ textDecoration: "none" }}
              >
                
                <h2 className="flex align-middle justify-start my-2.5 text-lg font-normal ">
                  {module.module_name}
                  
               
                </h2>
                <p className="opacity-50">
                    {module.module_description}
                  </p>
              </Link>
              {/* <label className="absolute top-1 right-2">
               
              </label> */}
            </div>
          ))
        ) : (
          // Render something else if there are no courses
          <div>No Core modules available</div>
        )}
      </div>
      </div>

      <div className="m-5 bg-[#1A1C1E] rounded-lg">
        <div className="flex items-center justify-between header-title">
          <h4>AI Enablement</h4>
          {storedUserId === CourseTrainerID ? (
          <div className="">
            <Button
              variant="contained"
              onClick={() => Addmodule('ai')}
              sx={{
                background: "#5b52e7",
                color: "#ffffff",
                fontFamily: "Poppins, sans-serif",
                "&:hover": {
                  background: "#5b52e7",
                },
              }}
            >
              <AddCircleOutlineIcon sx={{ mr: 1 }} /> Add AI Enablement Module
            </Button>
          </div>
        ) : null}
        </div>


      <div className="grid gap-6 p-8 overflow-x-auto 2xl:grid-cols-5 xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2">
        {modules?.length === 0 ? (
          // Loader component or loading message goes here
          <div>No AI Enablement available</div>
        ) : modules ? (
          modules?.map((module) => (
            <div
              key={module.id}
              className="bg-[#242728] border border-[#303234] rounded-md p-4 gap-3"
            >
              <Link
                to={`/admin/courses/${courseId}/module/${module.id}`}
                style={{ textDecoration: "none" }}
              >
                
                <h2 className="flex align-middle justify-start my-2.5 text-lg font-normal ">
                  {module.module_name}
                  
               
                </h2>
                <p className="opacity-50">
                    {module.module_description}
                  </p>
              </Link>
              {/* <label className="absolute top-1 right-2">
               
              </label> */}
            </div>
          ))
        ) : (
          // Render something else if there are no courses
          <div>No AI Enablement available</div>
        )}
      </div>
      </div>

      <div className="m-5 bg-[#1A1C1E] rounded-lg">
        <div className="flex items-center justify-between header-title">
          <h4>Getting Ready for Job</h4>
          {storedUserId === CourseTrainerID ? (
          <div className="">
            <Button
              variant="contained"
              onClick={() => Addmodule('interview')}
              sx={{
                background: "#5b52e7",
                color: "#ffffff",
                fontFamily: "Poppins, sans-serif",
                "&:hover": {
                  background: "#5b52e7",
                },
              }}
            >
              <AddCircleOutlineIcon sx={{ mr: 1 }} /> Add Interview Prep Module
            </Button>
          </div>
        ) : null}
        </div>


      <div className="grid gap-6 p-8 overflow-x-auto 2xl:grid-cols-5 xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2">
        {interview?.length === 0 ? (
          // Loader component or loading message goes here
          <div>No Data available</div>
        ) : interview ? (
          interview?.map((module) => (
            <div
              key={module.id}
              className="bg-[#242728] border border-[#303234] rounded-md p-4 gap-3"
            >
              <Link
                to={`/admin/courses/${courseId}/module/${module.id}`}
                style={{ textDecoration: "none" }}
              >
                
                <h2 className="flex align-middle justify-start my-2.5 text-lg font-normal ">
                  {module.module_name}
                  
               
                </h2>
                <p className="opacity-50">
                    {module.module_description}
                  </p>
              </Link>
              {/* <label className="absolute top-1 right-2">
               
              </label> */}
            </div>
          ))
        ) : (
          // Render something else if there are no courses
          <div>No Data available</div>
        )}
      </div>
      </div>

      <PromptsDataTable
                prompts={courseDetails?.training_course_prompt}
                onSavePrompt={handleSavePrompts}
                courseId={courseId}
              />
      
      

      
    </>
  );
};

export default Module;
