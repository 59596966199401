import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { useDispatch } from "react-redux";

import { AddNewmodule, getCourses, getModules } from "../../services/api";
import { TailSpin } from "react-loader-spinner";
import { Box, Button, Modal, TextField, Typography } from "@mui/material";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import TopNav from "components/TopNav";
import TopNavTrainee from "./TopNavTrainee";
import { fetchAllCourses } from "store/actions/course";
import { useReduxState } from "hooks/useReduxActions";
import CourceBot from "pages/CourceBot";


const Training = () => {
  // const { courseId } = useParams();
  const [showAddModal, setShowAddModal] = useState(false);
  const [courses, setCourses] = useState([]);
  const [newModule, setNewModule] = useState({
    name: "",
    description: "",
    youtubeLink: "",
  });

    // const dispatch = useReduxActions();
    const dispatch = useDispatch();
  
    useEffect(() => {
  
      dispatch(fetchAllCourses());
    }, [dispatch]);
  
    const coursesSelector = useReduxState((state) => state.course);
    useEffect(() => {
      if (!coursesSelector.error && coursesSelector.courses.length > 0) {
        setCourses(coursesSelector.courses);
      }
    }, [coursesSelector, courses]);


  const fetchCourses = async () => {
    try {
      const response = await getCourses();
      if (response) {
        setCourses(response?.data.courses);
      } else {
        toast.error("Something Went Wrong, Please Try Again.", {
          autoClose: 3000,
        });
      }
    } catch (error) {
      toast.error("Something Went Wrong, Please Try Again.", {
        autoClose: 3000,
      });
    }
  };

  // useEffect(() => {
  //   fetchCourses();
  // }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "youtubeLink") {
      setNewModule((prevModule) => ({
        ...prevModule,
        [name]: value,
      }));
    } else {
      setNewModule((prevModule) => ({
        ...prevModule,
        [name]: value,
      }));
    }
  };

  const addNewModule = async () => {
    const newModuleWithId = { ...newModule };
    try {
      const response = await AddNewmodule(newModuleWithId);
      

      setShowAddModal(false);
      setNewModule({
        name: "",
        description: "",
        youtubeLink: "",
      });
      await fetchCourses();
    } catch (error) {
      toast.error("Something Went Wrong, Please Try Again.", {
        autoClose: 3000,
      });
    }
  };
  const Addmodule = () => {
    setShowAddModal(true);
    // const id = generateId();
    // setGenerated(id);
  };
  
  const totalModules = courses?.length;
  return (
    <>
  <ToastContainer/>
      
      <TopNavTrainee />


      
      <div className="m-5 bg-[#1A1C1E] rounded-lg">
      <div className="header-title">
          <h4>All Courses</h4>
        </div>
       
        <div className="">
          {/* <Button
            variant="contained"
            onClick={() => Addmodule()}
            sx={{
              background: "#282B2F",
              color: "#BDBEBE",
              fontFamily: "Poppins, sans-serif",
              "&:hover": {
                background: "#282B2F",
              },
            }}
          >
            <AddCircleOutlineIcon sx={{ mr: 1 }} /> Add course
          </Button> */}
        </div>
      

      <Modal
        open={showAddModal}
        onClose={() => setShowAddModal(false)}
        aria-labelledby="add-new-course-modal"
        aria-describedby="form-to-add-new-course"
      >
        <Box className="absolute m-auto inset-0 h-fit lg:w-1/4 md:w-2/4 sm:w-2/4 max-sm:w-4/5 bg-[#242728] text-[#BDBEBE] border-2 border-[#37383A] p-8 rounded-lg">
          <Typography
            variant="h6"
            component="h2"
            sx={{ mb: 2, fontFamily: "Poppins, sans-serif" }}
          >
            Add New course
          </Typography>

          <TextField
            label="Name"
            variant="outlined"
            name="name"
            value={newModule.name}
            onChange={handleInputChange}
            fullWidth
            sx={{
              mb: 2,
              "& input": {
                color: "#BDBEBE !important",
                fontFamily: "Poppins, sans-serif",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "#37383A !important",
              },
              "& .MuiInputLabel-outlined": {
                color: "#BDBEBE !important",
              },
            }}
          />
          <TextField
            label="Description"
            variant="outlined"
            name="description"
            multiline
            rows={4}
            value={newModule.description}
            onChange={handleInputChange}
            fullWidth
            sx={{
              mb: 2,
              "& textarea": {
                color: "#BDBEBE !important",
                fontFamily: "Poppins, sans-serif",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "#37383A !important",
              },
              "& .MuiInputLabel-outlined": {
                color: "#BDBEBE !important",
              },
            }}
          />
          <TextField
            label="YouTube Link (Embed)"
            variant="outlined"
            name="youtubeLink"
            value={newModule.youtubeLink}
            onChange={handleInputChange}
            fullWidth
            sx={{
              mb: 2,
              "& input": {
                color: "#BDBEBE !important",
                fontFamily: "Poppins, sans-serif",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "#37383A !important",
              },
              "& .MuiInputLabel-outlined": {
                color: "#BDBEBE !important",
              },
            }}
          />
          <div className="flex justify-end">
            <Button
              onClick={() => setShowAddModal(false)}
              sx={{
                mr: 2,
                color: "#BDBEBE",
                fontFamily: "Poppins, sans-serif",
                "&:hover": {
                  background: "#282B2F",
                },
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={addNewModule}
              sx={{
                background: "#282B2F",
                color: "#BDBEBE",
                fontFamily: "Poppins, sans-serif",
                "&:hover": {
                  background: "#282B2F",
                },
              }}
            >
              Add
            </Button>
          </div>
        </Box>
      </Modal>      
      <div className="grid gap-6 p-8 overflow-x-auto 2xl:grid-cols-5 xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2">
        {courses?.length === 0 ? (
          // Loader component or loading message goes here
          <div className="flex justify-center">
            <TailSpin
              color="#FFFFFF"
              height={20}
              width={20}
              // style={{
              //   margin: "10px 10px",
              // }}
            />
          </div> // Render courses if not loading
        ) : courses ? (
          courses?.map((course) => (
            <div
              key={course.id}
              className="bg-[#242728] border border-[#303234] rounded-md p-4 gap-3"
            >
              <Link
                to={`/training/courses/${course.id}`}
                style={{ textDecoration: "none" ,color: "#ffffff", wordBreak:"break-word"}}
              >
                {course.youtubeLink && (
                  <div className="mb-6">
                    <iframe
                      title="YouTube Video"
                      width="100%"
                      height="315"
                      src={`https://www.youtube.com/embed/${course.youtubeLink}`}
                      frameBorder="0"
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  </div>
                )}
                <h2 className="flex align-middle justify-start my-2.5 text-lg font-normal">
                  {course.course_name}
                  {/* <FaEdit style={{ marginLeft: "50", cursor: "pointer" }} />{" "} */}
                  {/* Edit icon */}
                </h2>
                <p className="flex align-middle justify-start my-2.5 mx-2 text-base font-normal">
                  {course.module_description}
                </p>
              </Link>
              <label className="absolute top-1 right-2">
                {/* <input type="checkbox" /> */}
                {/* Edit icon */}
              </label>
            </div>
          ))
        ) : (
          // Render something else if there are no courses
          <div>No courses available</div>
        )}
      </div>
      </div>
      <CourceBot />
    </>
  );
};

export default Training;

