/* eslint-disable react-hooks/rules-of-hooks */
import { Link } from "react-router-dom";
import "styles/Consultant.css";
import logo from "../../data/logo-light.svg";
import logo_gray from "../../data/logo-gray.svg";

const sidebar = ({ sidebarOpen }) => {
  //   const [sidebarOpen, setSidebarOpen] = useState(false);
  // Change nav color when scrolling

  return (
    <>
      <style>
        {`
      .sidebar::before {
        content: "";
        width: 100%;
        height: 30%;
        position: absolute;
        bottom: 0;
        left: -10%;
        background: url(${logo_gray}) no-repeat bottom / contain;
      }
      `}
      </style>
      <aside
        className={`w-72 flex flex-col bg-[#1A1C1E] text-white fixed inset-y-0 left-0 transition-all duration-300 ease-in-out z-30 sidebar ${
          sidebarOpen ? "-translate-x-full" : ""
        }`}
      >
        <nav className="flex-1 flex flex-col bg-[#1A1C1E] border-r border-[#37383A] pt-4">
          <Link
            to="/training"
            // onClick={handleCloseSidebar}
            className="flex items-center w-full gap-3 text-xl font-extrabold tracking-tight text-white uppercase dark:text-white"
          >
            <span className="w-full p-5 pt-0">
              <img src={logo} alt="logo"></img>
            </span>
            {/* <span><img src={logo} alt="logo"></img></span> */}
          </Link>
          <Link to="/training" className="nav-link">
            Training
          </Link>
          <Link to="/training/interviewprep" className="nav-link">
            Interview Prep
          </Link>
          {/* <Link to="/training/copilot" className="nav-link">
            Copilot
          </Link> */}
          <Link to="https://hcomb-bigagi-aca.victoriousbush-67842c2f.eastus.azurecontainerapps.io/" target="_blank" className="nav-link">
            Copilot
          </Link>

          <Link to="https://discord.gg/Bvsxy6KY" target="_blank" className="nav-link">
          Hands on Support
          </Link>
        </nav>
      </aside>
    </>
  );
};

export default sidebar;
