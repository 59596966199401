import { useContext, useEffect, useMemo, useState } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { AuthContext } from "../contexts/authProvider";
import { useAuthInfo } from "@propelauth/react";

const Auth = ({ allowedRoles }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const authInfo = useAuthInfo();

  const { auth } = useContext(AuthContext);
  const location = useLocation();


  const isAuthorizedRole = useMemo(
    () => allowedRoles.includes(auth.role),
    [allowedRoles, auth]
  );


  useEffect(() => {
  }, [isAuthorizedRole]);

  if (!authInfo.userClass) {
    
    return null;
  }

  return (
    <>
      {isAuthorizedRole ? (
        <Outlet />
      ) : (
        <Navigate to="/unauthorized" state={{ from: location }} replace />
      )}
    </>
  );
};

export default Auth;
