import { Link } from "@mui/material";
import React, { useEffect, useState } from "react";
import "styles/Consultant.css";
import BluedropISMImg from "../pages/img/Bluedrop_ISM.png";
import BravantiImg from "../pages/img/Bravanti.png";
import CareerLifeAcademyImg from "../pages/img/Career-Life-Academy.png";
import CulverStocktonImg from "../pages/img/Culver_Stockton.png";
import DaltonStateImg from "../pages/img/Dalton_State.png";
import ECAImg from "../pages/img/ECA.png";
import FontbonneUniversityImg from "../pages/img/Fontbonne_University.png";
import GeorgianCollegeImg from "../pages/img/Georgian_College.png";
import ImpactGroupImg from "../pages/img/Impact_Group.png";
import KeystonePartnersImg from "../pages/img/Keystone_Partners.png";
import MohawkCollegeImg from "../pages/img/Mohawk_College.png";
import PodiumEducationImg from "../pages/img/Podium_Education.png";
import PurpleBriefcaseNewImg from "../pages/img/Purple_Briefcase_New.png";
import ReinhardtUniversityImg from "../pages/img/Reinhardt_University.png";
import SenecaCollegeImg from "../pages/img/Seneca_College.png";
import ThriveNEWImg from "../pages/img/Thrive_NEW.png";
import WebsterImg from "../pages/img/Webster.png";
import AccountantImg from "../pages/img/accountant.svg";
import AppleImg from "../pages/img/apple.svg";
import ArchitectureImg from "../pages/img/architecture.png";
import BalanceImg from "../pages/img/balance.svg";
import BankImg from "../pages/img/bank.svg";
import InterviewImg from "../pages/img/build-interview-confidence.svg";
import BuildInterviewImg from "../pages/img/build-interview.svg";
import BuiltInCameraImg from "../pages/img/built-in-camera.svg";
import CalendarImg from "../pages/img/calendar.svg";
import CapitolImg from "../pages/img/capitol.svg";
import AdvisorImg from "../pages/img/career-advisor.webp";
import DinnerImg from "../pages/img/dinner.svg";
import DnaImg from "../pages/img/dna.svg";
import EarnmoreImg from "../pages/img/earn-more.svg";
import EmployeeImg from "../pages/img/employee.svg";
import FacebookImg from "../pages/img/facebook.svg";
import FirstAidKitImg from "../pages/img/first-aid-kit.svg";
import HiredImg from "../pages/img/get-hired-faster.svg";
import GTJobGraphicImg from "../pages/img/gtJobGraphic.webp";
import GuideGraphicImg from "../pages/img/guideGraphic2-new.webp";
import BannerImg1 from "../pages/img/hmBannerImg.png";
import ImproveImg from "../pages/img/improve.svg";
import InstagramImg from "../pages/img/instagram.svg";
import InsuranceImg from "../pages/img/insurance.svg";
import InterviewsAnywhereImg from "../pages/img/interviews-anywhere.svg";
import JobImg from "../pages/img/land-the-job.svg";
import LinkedinImg from "../pages/img/linkedin.svg";
import LoveImg from "../pages/img/love.svg";
import MegaphoneImg from "../pages/img/megaphone.svg";
import NewsImg from "../pages/img/news.svg";
import PaintingImg from "../pages/img/painting.svg";
import BannerImg2 from "../pages/img/rdHmSecImg.webp";
import BannerImg4 from "../pages/img/rdHmSecThreeImg.webp";
import BannerImg3 from "../pages/img/rdHmSecTwoImg.webp";
import ResponsiveImg from "../pages/img/responsive.svg";
import SharePracticesImg from "../pages/img/share-practices.svg";
import SheriffImg from "../pages/img/sheriff.svg";
import SketchingImg from "../pages/img/sketching.svg";
import SkillsImg from "../pages/img/skills.svg";
import StudyingImg from "../pages/img/studying.svg";
import TrolleyImg from "../pages/img/trolley.svg";

const Consultant = ({ sidebarOpen }) => {
  // Sidebar Open/Close
  // const [sidebarOpen, setSidebarOpen] = useState(false);

  // Change nav color when scrolling
  const [color, setColor] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      if (offset > 0) {
        setColor(true);
      } else {
        setColor(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <div className="flex h-screen overflow-hidden">
     
        <div className="flex-1 overflow-y-auto bg-[#1A1C1E]">
         

          <main className="p-4">
            <div className="bg-[#1A1C1E] pt-32 pb-16 px-40">
              <div className="container mx-auto px-3 w-full ">
                <div className="row flex relative w-full items-start justify-between">
                  <div className="grid grid-cols-2">
                    <div className="pr-16 pt-3.5">
                      <h1 className="text-3xl text-[#fdfdfd] mb-6 font-extrabold">
                        Master the interview & land a job worth loving.
                      </h1>
                      <p className="text-md text-[#fdfdfd] tracking-[.53px] mb-6">
                        Simulate realistic interviews for over 120 different job
                        positions and level up your skills in no time.
                      </p>
                      <Link to="#" className="custom-link">
                        Learn More
                      </Link>
                    </div>
                    <div>
                      <img
                        className="max-w-full h-auto"
                        src={BannerImg1}
                        alt="banner-img1"
                      />
                    </div>
                  </div>
                </div>

                <div className="row flex relative w-full items-start justify-between pt-16 pb-20">
                  <div className="grid grid-cols-2">
                    <div>
                      <img
                        className="max-w-full h-auto"
                        src={BannerImg2}
                        alt="banner-img2"
                      />
                    </div>
                    <div className="pl-16 pt-6">
                      <h3 className="relative tpBar pt-6 text-3xl text-[#fdfdfd] mb-5 font-extrabold tracking-[-.2px]">
                        Take Mock Interviews On Your Own
                      </h3>
                      <p className="text-md text-[#fdfdfd] tracking-[.53px] mb-6">
                        Take unlimited interviews and master your skills from
                        anywhere. No awkward meetups required.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="bg-[#1A1C1E] pt-28 mt-[-190px] px-40">
              <div className="container mx-auto px-3 w-full">
                <div className="row flex relative w-full items-start justify-between">
                  <div className="grid grid-cols-2 pt-3.5">
                    <div className="pr-16 pt-28 pl-20">
                      <h3 className="relative tpBar pt-6 text-3xl text-[#fdfdfd] mb-6 font-extrabold">
                        Practice for the Pressure
                      </h3>
                      <p className="text-md text-[#fdfdfd] tracking-[.53px] mb-6">
                        We use your built-in camera to recreate the pressure of
                        actual interviews so you can gain realistic experience
                        and feel prepared for anything.
                      </p>
                    </div>
                    <div>
                      <img
                        className="max-w-full h-auto"
                        src={BannerImg3}
                        alt="banner-img3"
                      />
                    </div>
                  </div>
                </div>

                <div className="row flex relative w-full items-start justify-between">
                  <div className="grid grid-cols-2 mt-6">
                    <div>
                      <img
                        className="max-w-full h-auto"
                        src={BannerImg4}
                        alt="banner-img4"
                      />
                    </div>
                    <div className="pl-16 pt-14">
                      <h3 className="relative tpBar pt-6 text-3xl text-[#fdfdfd] mb-5 font-extrabold tracking-[-.2px]">
                        Review Your Recorded Responses
                      </h3>
                      <p className="text-md text-[#fdfdfd] tracking-[.53px] mb-6">
                        Your responses are automatically recorded, so you can
                        watch them after your interview and know exactly how you
                        came across.
                      </p>
                      <Link to="#" className="custom-link">
                        Get Started
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-24 pt-9 w-full bg-gradient-to-b from-gray-400 to-transparent">
              <div className="container mx-auto px-3 max-w-[750px]">
                <div className="row flex relative w-full items-start justify-between">
                  <div className="grid grid-cols-2 gap-16 pb-[89px] relative w-full px-3">
                    <div>
                      <img
                        className="max-w-full h-auto align-middle"
                        src={InterviewImg}
                        alt="interview-img"
                      />
                      <h3 className="text-2xl text-[#1a1C1E] mb-5 font-extrabold">
                        Build interview confidence.
                      </h3>
                      <p className="text-sm text-[#1a1C1E] tracking-[.14px] mb-4">
                        We give you everything you need to master your interview
                        skills in less time than any other option, so you can
                        walk into your interview with confidence.
                      </p>
                    </div>
                    <div>
                      <img
                        className="max-w-full h-auto align-middle"
                        src={HiredImg}
                        alt="hired-img"
                      />
                      <h3 className="text-2xl text-[#1a1C1E] mb-5 font-extrabold">
                        Get hired faster.
                      </h3>
                      <p className="text-sm text-[#1a1C1E] tracking-[.14px] mb-4">
                        Our simulator is optimized to help you master your
                        interview skills in the most efficient way possible, so
                        you can be prepared to ace the interview in no time.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="row flex relative w-full items-start justify-between">
                  <div className="grid grid-cols-2 gap-12 relative w-full px-3">
                    <div>
                      <img
                        className="max-w-full h-auto align-middle"
                        src={EarnmoreImg}
                        alt="earnmore-img"
                      />
                      <h3 className="text-2xl text-[#fdfdfd] mb-5 font-extrabold">
                        Accelerate your career & earn more.
                      </h3>
                      <p className="text-sm text-[#fdfdfd] tracking-[.14px] mb-4">
                        Master the skill of interviewing by practicing it just
                        like you practice your trade and give your career a
                        boost.
                      </p>
                    </div>
                    <div>
                      <img
                        className="max-w-full h-auto align-middle"
                        src={JobImg}
                        alt="job-img"
                      />
                      <h3 className="text-2xl text-[#fdfdfd] mb-5 font-extrabold">
                        Land the job you've been dreaming of.
                      </h3>
                      <p className="text-sm text-[#fdfdfd] tracking-[.14px] mb-4">
                        Gain realistic interview experience and master the
                        skills you need to wow your employers and beat out the
                        competition.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-24 pt-[100px] pb-[120px] relative w-full px-40">
              <div className="container mx-auto px-3 w-full">
                <div className="row flex items-center">
                  <div className="grid grid-cols-2 gap-12 w-full pr-[50px] items-center">
                    <div>
                      <video
                        id="video"
                        class="relative inset-0 w-full h-full object-cover myVideo bg-black"
                        playsinline
                        crossorigin="anonymous"
                        preload="auto"
                        controls
                        poster="images/video_poster/introduction.png"
                      ></video>
                    </div>
                    <div>
                      <h3 className="relative tpBar pt-6 text-3xl text-[#fdfdfd] mb-5 font-extrabold">
                        Complete Online Video Course
                      </h3>
                      <p className="text-md text-[#fdfdfd] tracking-[.14px] mb-6">
                        Become an interview expert with this series of
                        fun-to-watch lessons. We’ll teach you how to avoid
                        common pitfalls so you can ace the interview.
                      </p>
                      <Link to="#" className="custom-link">
                        Learn More
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="pt-14 pb-16 relative w-full careerAdvisorSec px-40">
              <div className="container mx-auto px-3 w-full ">
                <div className="row flex items-center">
                  <div className="grid grid-cols-2 w-full relative">
                    <div className="w-96">
                      <h4 className="text-sm text-[#fdfdfd] tracking-[3.89px] uppercase mb-2">
                        For Career Advisors
                      </h4>
                      <h3 className="pt-6 text-3xl text-[#fdfdfd] mb-5 font-extrabold tracking-[.2px]">
                        Mock Interviews They Can Take on Their Own
                      </h3>
                      <p className="text-md text-[#fdfdfd] tracking-[.13px] mb-7">
                        Provide simulated interviews they can conduct on their
                        own. No need to schedule, commute, or meet in person.
                      </p>
                      <Link to="#" className="custom-link">
                        Learn More
                      </Link>
                    </div>
                    <div className="hidden">
                      <img
                        className="w-full h-auto"
                        src={AdvisorImg}
                        alt="advisor-img"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="pt-28 pb-28 w-full bg-[#1A1C1E] px-40">
              <div className="container mx-auto max-w-[940px]">
                <div className="row flex justify-center">
                  <div className="grid grid-cols-3 gap-20">
                    <div className="flex flex-wrap items-center justify-center content-start text-center">
                      <img
                        className="max-w-full h-auto pb-5"
                        src={BuildInterviewImg}
                        alt="build-interview-img"
                      />
                      <h3 className="text-2xl text-[#fdfdfd] mb-2.5 font-extrabold">
                        Build interviews.
                      </h3>
                      <p className="text-sm text-[#fdfdfd]">
                        Using your own questions.
                      </p>
                    </div>
                    <div className="flex flex-wrap items-center justify-center content-start text-center">
                      <img
                        className="max-w-full h-auto pb-5"
                        src={SkillsImg}
                        alt="skills-img"
                      />
                      <h3 className="text-2xl text-[#fdfdfd] mb-2.5 font-extrabold">
                        Level up your skills.
                      </h3>
                      <p className="text-sm text-[#fdfdfd]">
                        With our exclusive interview training program.
                      </p>
                    </div>
                    <div className="flex flex-wrap items-center justify-center content-start text-center">
                      <img
                        className="max-w-full h-auto pb-5"
                        src={SharePracticesImg}
                        alt="share-practices-img"
                      />
                      <h3 className="text-2xl text-[#fdfdfd] mb-2.5 font-extrabold">
                        Share practices.
                      </h3>
                      <p className="text-sm text-[#fdfdfd]">
                        Get feedback from anyone.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="pt-[90px] w-full relative float-left bg-gradient-to-t from-gray-400 to-transperent">
              <div className="container mx-auto max-w-[1320px]">
                <h3 className="text-sm text-[#fdfdfd] opacity-70 mb-10 uppercase text-center tracking-[3.5px]">
                  Our Partners
                </h3>
                <div className="row flex justify-center">
                  <div className="grid grid-cols-6 gap-20 items-center">
                    <div className="w-24 mb-5">
                      <img
                        className="max-w-28 h-auto align-middle"
                        src={PurpleBriefcaseNewImg}
                        alt="purple-briefcase-new-img"
                      />
                    </div>
                    <div className="w-24 mb-5">
                      <img
                        className="max-w-28 h-auto align-middle"
                        src={ThriveNEWImg}
                        alt="purple-briefcase-new-img"
                      />
                    </div>
                    <div className="w-24 mb-5">
                      <img
                        className="max-w-28 h-auto align-middle"
                        src={CareerLifeAcademyImg}
                        alt="purple-briefcase-new-img"
                      />
                    </div>
                    <div className="w-24 mb-5">
                      <img
                        className="max-w-28 h-auto align-middle"
                        src={WebsterImg}
                        alt="purple-briefcase-new-img"
                      />
                    </div>
                    <div className="w-24 mb-5">
                      <img
                        className="max-w-28 h-auto align-middle"
                        src={KeystonePartnersImg}
                        alt="purple-briefcase-new-img"
                      />
                    </div>
                    <div className="w-24 mb-5">
                      <img
                        className="max-w-28 h-auto align-middle"
                        src={ImpactGroupImg}
                        alt="purple-briefcase-new-img"
                      />
                    </div>
                  </div>
                </div>

                <div className="row flex justify-center">
                  <div className="grid grid-cols-6 gap-20 items-center">
                    <div className="w-24 mb-5">
                      <img
                        className="max-w-28 h-auto align-middle"
                        src={DaltonStateImg}
                        alt="purple-briefcase-new-img"
                      />
                    </div>
                    <div className="w-24 mb-5">
                      <img
                        className="max-w-28 h-auto align-middle"
                        src={BluedropISMImg}
                        alt="purple-briefcase-new-img"
                      />
                    </div>
                    <div className="w-24 mb-5">
                      <img
                        className="max-w-28 h-auto align-middle"
                        src={BravantiImg}
                        alt="purple-briefcase-new-img"
                      />
                    </div>
                    <div className="w-24 mb-5">
                      <img
                        className="max-w-28 h-auto align-middle"
                        src={CulverStocktonImg}
                        alt="purple-briefcase-new-img"
                      />
                    </div>
                    <div className="w-24 mb-5">
                      <img
                        className="max-w-28 h-auto align-middle"
                        src={ECAImg}
                        alt="purple-briefcase-new-img"
                      />
                    </div>
                    <div className="w-24 mb-5">
                      <img
                        className="max-w-28 h-auto align-middle"
                        src={FontbonneUniversityImg}
                        alt="purple-briefcase-new-img"
                      />
                    </div>
                  </div>
                </div>

                <div className="row flex justify-center">
                  <div className="grid grid-cols-5 gap-20 items-center">
                    <div className="w-24 mb-5">
                      <img
                        className="max-w-28 h-auto align-middle"
                        src={GeorgianCollegeImg}
                        alt="purple-briefcase-new-img"
                      />
                    </div>
                    <div className="w-24 mb-5">
                      <img
                        className="max-w-28 h-auto align-middle"
                        src={MohawkCollegeImg}
                        alt="purple-briefcase-new-img"
                      />
                    </div>
                    <div className="w-24 mb-5">
                      <img
                        className="max-w-28 h-auto align-middle"
                        src={PodiumEducationImg}
                        alt="purple-briefcase-new-img"
                      />
                    </div>
                    <div className="w-24 mb-5">
                      <img
                        className="max-w-28 h-auto align-middle"
                        src={ReinhardtUniversityImg}
                        alt="purple-briefcase-new-img"
                      />
                    </div>
                    <div className="w-24 mb-5">
                      <img
                        className="max-w-28 h-auto align-middle"
                        src={SenecaCollegeImg}
                        alt="purple-briefcase-new-img"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="pt-24 w-full relative float-left bg-[#1A1C1E] px-40">
              <div className="container mx-auto max-w-[1320px]">
                <h2 className="relative tpBar text-3xl font-bold text-[#fdfdfd] mb-10 text-center pt-7">
                  Mock Interviews for Over<br></br> 120 Different Job Titles
                </h2>
                <div className="row flex justify-center">
                  <div className="grid grid-cols-7 items-start">
                    <div className="flex flex-wrap justify-center w-28 hover:bg-[#28303d] py-3.5 px-4 rounded-2xl align-top mx-3.5">
                      <img
                        className="w-full h-full align-middle mb-2.5 rounded-full bg-[#dedede]"
                        src={AccountantImg}
                        alt="accountant-img"
                      />
                      <span class="text-[#fdfdfd] text-[10px] font-bold tracking-[.09px] text-center">
                        Accounting
                      </span>
                    </div>
                    <div className="flex flex-wrap justify-center w-28 hover:bg-[#28303d] py-3.5 px-4 rounded-2xl align-top mx-3.5">
                      <img
                        className="w-full h-full align-middle mb-2.5 rounded-full bg-[#dedede]"
                        src={CalendarImg}
                        alt="calendar-img"
                      />
                      <span class="text-[#fdfdfd] text-[10px] font-bold tracking-[.09px] text-center">
                        Administrative Support
                      </span>
                    </div>
                    <div className="flex flex-wrap justify-center w-28 hover:bg-[#28303d] py-3.5 px-4 rounded-2xl align-top mx-3.5">
                      <img
                        className="w-full h-full align-middle mb-2.5 rounded-full bg-[#dedede]"
                        src={ArchitectureImg}
                        alt="architecture-img"
                      />
                      <span class="text-[#fdfdfd] text-[10px] font-bold tracking-[.09px] text-center">
                        Architecture & Construction
                      </span>
                    </div>
                    <div className="flex flex-wrap justify-center w-28 hover:bg-[#28303d] py-3.5 px-4 rounded-2xl align-top mx-3.5">
                      <img
                        className="w-full h-full align-middle mb-2.5 rounded-full bg-[#dedede]"
                        src={PaintingImg}
                        alt="painting-img"
                      />
                      <span class="text-[#fdfdfd] text-[10px] font-bold tracking-[.09px] text-center">
                        Arts
                      </span>
                    </div>
                    <div className="flex flex-wrap justify-center w-28 hover:bg-[#28303d] py-3.5 px-4 rounded-2xl align-top mx-3.5">
                      <img
                        className="w-full h-full align-middle mb-2.5 rounded-full bg-[#dedede]"
                        src={BankImg}
                        alt="bank-img"
                      />
                      <span class="text-[#fdfdfd] text-[10px] font-bold tracking-[.09px] text-center">
                        Banking & Finance
                      </span>
                    </div>
                    <div className="flex flex-wrap justify-center w-28 hover:bg-[#28303d] py-3.5 px-4 rounded-2xl align-top mx-3.5">
                      <img
                        className="w-full h-full align-middle mb-2.5 rounded-full bg-[#dedede]"
                        src={AppleImg}
                        alt="apple-img"
                      />
                      <span class="text-[#fdfdfd] text-[10px] font-bold tracking-[.09px] text-center">
                        Education
                      </span>
                    </div>
                    <div className="flex flex-wrap justify-center w-28 hover:bg-[#28303d] py-3.5 px-4 rounded-2xl align-top mx-3.5">
                      <img
                        className="w-full h-full align-middle mb-2.5 rounded-full bg-[#dedede]"
                        src={SketchingImg}
                        alt="sketching-img"
                      />
                      <span class="text-[#fdfdfd] text-[10px] font-bold tracking-[.09px] text-center">
                        Engineering
                      </span>
                    </div>
                  </div>
                </div>

                <div className="row flex justify-center">
                  <div className="grid grid-cols-7 items-start">
                    <div className="flex flex-wrap justify-center w-28 hover:bg-[#28303d] py-3.5 px-4 rounded-2xl align-top mx-3.5">
                      <img
                        className="w-full h-full align-middle mb-2.5 rounded-full bg-[#dedede]"
                        src={CapitolImg}
                        alt="capitol-img"
                      />
                      <span class="text-[#fdfdfd] text-[10px] font-bold tracking-[.09px] text-center">
                        Government & Public Admin
                      </span>
                    </div>
                    <div className="flex flex-wrap justify-center w-28 hover:bg-[#28303d] py-3.5 px-4 rounded-2xl align-top mx-3.5">
                      <img
                        className="w-full h-full align-middle mb-2.5 rounded-full bg-[#dedede]"
                        src={FirstAidKitImg}
                        alt="first-aid-kit-img"
                      />
                      <span class="text-[#fdfdfd] text-[10px] font-bold tracking-[.09px] text-center">
                        Health Science
                      </span>
                    </div>
                    <div className="flex flex-wrap justify-center w-28 hover:bg-[#28303d] py-3.5 px-4 rounded-2xl align-top mx-3.5">
                      <img
                        className="w-full h-full align-middle mb-2.5 rounded-full bg-[#dedede]"
                        src={DinnerImg}
                        alt="dinner-img"
                      />
                      <span class="text-[#fdfdfd] text-[10px] font-bold tracking-[.09px] text-center">
                        Hospitality & Tourism
                      </span>
                    </div>
                    <div className="flex flex-wrap justify-center w-28 hover:bg-[#28303d] py-3.5 px-4 rounded-2xl align-top mx-3.5">
                      <img
                        className="w-full h-full align-middle mb-2.5 rounded-full bg-[#dedede]"
                        src={EmployeeImg}
                        alt="employee-img"
                      />
                      <span class="text-[#fdfdfd] text-[10px] font-bold tracking-[.09px] text-center">
                        Human Resources
                      </span>
                    </div>
                    <div className="flex flex-wrap justify-center w-28 hover:bg-[#28303d] py-3.5 px-4 rounded-2xl align-top mx-3.5">
                      <img
                        className="w-full h-full align-middle mb-2.5 rounded-full bg-[#dedede]"
                        src={LoveImg}
                        alt="love-img"
                      />
                      <span class="text-[#fdfdfd] text-[10px] font-bold tracking-[.09px] text-center">
                        Human Services
                      </span>
                    </div>
                    <div className="flex flex-wrap justify-center w-28 hover:bg-[#28303d] py-3.5 px-4 rounded-2xl align-top mx-3.5">
                      <img
                        className="w-full h-full align-middle mb-2.5 rounded-full bg-[#dedede]"
                        src={ResponsiveImg}
                        alt="responsive-img"
                      />
                      <span class="text-[#fdfdfd] text-[10px] font-bold tracking-[.09px] text-center">
                        Information Technology
                      </span>
                    </div>
                    <div className="flex flex-wrap justify-center w-28 hover:bg-[#28303d] py-3.5 px-4 rounded-2xl align-top mx-3.5">
                      <img
                        className="w-full h-full align-middle mb-2.5 rounded-full bg-[#dedede]"
                        src={InsuranceImg}
                        alt="insurance-img"
                      />
                      <span class="text-[#fdfdfd] text-[10px] font-bold tracking-[.09px] text-center">
                        Insurance
                      </span>
                    </div>
                  </div>
                </div>

                <div className="row flex justify-center">
                  <div className="grid grid-cols-7 items-start">
                    <div className="flex flex-wrap justify-center w-28 hover:bg-[#28303d] py-3.5 px-4 rounded-2xl align-top mx-3.5">
                      <img
                        className="w-full h-full align-middle mb-2.5 rounded-full bg-[#dedede]"
                        src={NewsImg}
                        alt="news-img"
                      />
                      <span class="text-[#fdfdfd] text-[10px] font-bold tracking-[.09px] text-center">
                        Journalism & Broadcasting
                      </span>
                    </div>
                    <div className="flex flex-wrap justify-center w-28 hover:bg-[#28303d] py-3.5 px-4 rounded-2xl align-top mx-3.5">
                      <img
                        className="w-full h-full align-middle mb-2.5 rounded-full bg-[#dedede]"
                        src={BalanceImg}
                        alt="balance-img"
                      />
                      <span class="text-[#fdfdfd] text-[10px] font-bold tracking-[.09px] text-center">
                        Law
                      </span>
                    </div>
                    <div className="flex flex-wrap justify-center w-28 hover:bg-[#28303d] py-3.5 px-4 rounded-2xl align-top mx-3.5">
                      <img
                        className="w-full h-full align-middle mb-2.5 rounded-full bg-[#dedede]"
                        src={MegaphoneImg}
                        alt="megaphone-img"
                      />
                      <span class="text-[#fdfdfd] text-[10px] font-bold tracking-[.09px] text-center">
                        Marketing, Sales & Service
                      </span>
                    </div>
                    <div className="flex flex-wrap justify-center w-28 hover:bg-[#28303d] py-3.5 px-4 rounded-2xl align-top mx-3.5">
                      <img
                        className="w-full h-full align-middle mb-2.5 rounded-full bg-[#dedede]"
                        src={SheriffImg}
                        alt="sheriff-img"
                      />
                      <span class="text-[#fdfdfd] text-[10px] font-bold tracking-[.09px] text-center">
                        Public Safety & Security
                      </span>
                    </div>
                    <div className="flex flex-wrap justify-center w-28 hover:bg-[#28303d] py-3.5 px-4 rounded-2xl align-top mx-3.5">
                      <img
                        className="w-full h-full align-middle mb-2.5 rounded-full bg-[#dedede]"
                        src={StudyingImg}
                        alt="studying-img"
                      />
                      <span class="text-[#fdfdfd] text-[10px] font-bold tracking-[.09px] text-center">
                        School Admissions
                      </span>
                    </div>
                    <div className="flex flex-wrap justify-center w-28 hover:bg-[#28303d] py-3.5 px-4 rounded-2xl align-top mx-3.5">
                      <img
                        className="w-full h-full align-middle mb-2.5 rounded-full bg-[#dedede]"
                        src={DnaImg}
                        alt="dna-img"
                      />
                      <span class="text-[#fdfdfd] text-[10px] font-bold tracking-[.09px] text-center">
                        Science & Mathematics
                      </span>
                    </div>
                    <div className="flex flex-wrap justify-center w-28 hover:bg-[#28303d] py-3.5 px-4 rounded-2xl align-top mx-3.5">
                      <img
                        className="w-full h-full align-middle mb-2.5 rounded-full bg-[#dedede]"
                        src={TrolleyImg}
                        alt="trolley-img"
                      />
                      <span class="text-[#fdfdfd] text-[10px] font-bold tracking-[.09px] text-center">
                        Transportation, Distribution & Logistics
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="pt-[75px] pb-[85px] w-full relative float-left bg-[#1A1C1E] text-center px-40">
              <div className="container mx-auto max-w-[400px]">
                <h4 className="text-sm text-[#fdfdfd] opacity-70 tracking-[3.5px] leading-4 text-center uppercase mb-8">
                  Get the Job Guarantee
                </h4>
                <img
                  className="max-w-[185px] h-auto inline-block mb-8"
                  src={GTJobGraphicImg}
                  alt="gt-job-graphic-img"
                />
                <h4 className="text-3xl font-bold text-[#fdfdfd] leading-5 mb-5">
                  Our Promise to You
                </h4>
                <p className="text-sm text-[#fdfdfd] opacity-80 text-center leading-7">
                  We're so confident we can get you interview-ready, we're
                  introducing our Get the Job Guarantee. If you don't get the
                  job, we'll give you your money back. Guaranteed.
                </p>
              </div>
            </div>

            <div className="bg-gradient-to-b from-[#1A1C1E] to-[#28303d] pt-20 pb-10 w-full relative float-left">
              <div className="container mx-auto px-3 w-full">
                <div className="row">
                  <div className="grid grid-cols-2 items-center">
                    <div>
                      <img
                        className="max-w-full h-auto"
                        src={GuideGraphicImg}
                        alt="banner-img4"
                      />
                    </div>
                    <div className="">
                      <h3 className="relative tpBar pt-6 text-3xl text-[#fdfdfd] mb-5 font-extrabold tracking-[-.2px]">
                        Get a leg up with our training program.
                      </h3>
                      <p className="text-md text-[#fdfdfd] tracking-[.53px] mb-6">
                        Our training program will teach you how to ace the
                        interview and exceed your interviewer's expectations.
                        From preparation to negotiation, we've got you covered.
                      </p>
                      <Link href="#" className="custom-link">
                        Learn More
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="testimonail bg-[#28303d] pt-28 pb-20 w-full relative float-left px-40">
              <div className="container mx-auto px-3 max-w-[1020px]">
                <div className="row">
                  <p className="text-2xl font-bold text-white tracking-[.32px] text-center mb-12 leading-10">
                    "Interviewing can be nerve-wracking, but with this
                    interactive tool I was able to gain confidence by polishing
                    my answers, being aware of timing, and paying attention to
                    my non-verbal language - things that I would not have paid
                    attention to otherwise."
                  </p>
                  <p className="text-sm text-white flex text-center justify-center tracking-[.19px] items-center leading-9">
                    -Marlene Tillman, Digital Marketer
                  </p>
                </div>
              </div>
            </div>

            <div className="bg-[#1a1C1E] pt-28 pb-20 w-full relative float-left px-40">
              <div className="container mx-auto px-3 max-w-[1020px]">
                <div className="row">
                  <div className="grid grid-cols-3 gap-20">
                    <div className="flex flex-wrap max-w-72 relative text-center content-start justify-center">
                      <img
                        className="max-w-full h-auto"
                        src={InterviewsAnywhereImg}
                        alt="interviews-anywhere-img"
                      />
                      <h3 className="text-lg text-[#fdfdfd] mb-2.5 leading-7 font-extrabold">
                        Simulate Realistic Interviews Anywhere
                      </h3>
                      <p className="text-sm text-[#fdfdfd] leading-5">
                        Take unlimited mock interviews whenever you want,
                        wherever you want.
                      </p>
                    </div>

                    <div className="flex flex-wrap max-w-72 relative text-center content-start justify-center">
                      <img
                        className="max-w-full h-auto"
                        src={BuiltInCameraImg}
                        alt="built-in-camera-img"
                      />
                      <h3 className="text-lg text-[#fdfdfd] mb-2.5 leading-7 font-extrabold">
                        Put On the Pressure with Your Built-In Camera
                      </h3>
                      <p className="text-sm text-[#fdfdfd] leading-5">
                        We raise the stakes by recording your responses to
                        create realistic interview pressure.
                      </p>
                    </div>

                    <div className="flex flex-wrap max-w-72 relative text-center content-start justify-center">
                      <img
                        className="max-w-full h-auto"
                        src={ImproveImg}
                        alt="ImproveImg-img"
                      />
                      <h3 className="text-lg text-[#fdfdfd] mb-2.5 leading-7 font-extrabold">
                        Watch Your Recorded Interviews and Improve
                      </h3>
                      <p className="text-sm text-[#fdfdfd] leading-5">
                        Know exactly how you came across and refine your
                        approach to nail the interview.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>

          <footer className="w-full relative float-left bg-[#1a1C1E] text-center pt-14 pb-14 px-40">
            <div className="container mx-auto max-w-[1320px]">
              <div className="row">
                <div className="grid grid-cols-1">
                  <div className="w-full relative float-left pb-14">
                    <ul className="list-none m-0 p-0">
                      <li className="inline-block mx-5 p-0 float-none">
                        <Link to="#" className="footer-link ">
                          Blog
                        </Link>
                      </li>
                      <li className="inline-block mx-5 p-0 float-none">
                        <Link to="#" className="footer-link">
                          Contact Us
                        </Link>
                      </li>
                      <li className="inline-block mx-5 p-0 float-none">
                        <Link to="#" className="footer-link">
                          FAQs
                        </Link>
                      </li>
                      <li className="inline-block mx-5 p-0 float-none">
                        <Link to="#" className="footer-link">
                          Support & How-To Videos
                        </Link>
                      </li>
                      <li className="inline-block mx-5 p-0 float-none">
                        <Link to="#" className="footer-link">
                          Terms
                        </Link>
                      </li>
                      <li className="inline-block mx-5 p-0 float-none">
                        <Link to="#" className="footer-link">
                          Privacy
                        </Link>
                      </li>
                    </ul>
                  </div>

                  <div className="w-full relative float-left pb-14">
                    <Link
                      to="#"
                      target="_blank"
                      sx={{
                        display: "inline-block",
                        fontSize: "16px",
                        margin: "0 20px",
                        cursor: "pointer",
                      }}
                    >
                      <img
                        className="w-5 h-auto rounded-sm"
                        src={FacebookImg}
                        alt="facebook-img"
                      />
                    </Link>
                    <Link
                      to="#"
                      target="_blank"
                      sx={{
                        display: "inline-block",
                        fontSize: "16px",
                        margin: "0 20px",
                        cursor: "pointer",
                      }}
                    >
                      <img
                        className="w-5 h-auto rounded-sm"
                        src={InstagramImg}
                        alt="instagram-img"
                      />
                    </Link>
                    <Link
                      to="#"
                      target="_blank"
                      sx={{
                        display: "inline-block",
                        fontSize: "16px",
                        margin: "0 20px",
                        cursor: "pointer",
                        borderRadius: "2px",
                        background: "white",
                      }}
                    >
                      <img
                        className="w-5 h-auto rounded-sm p-1"
                        src={LinkedinImg}
                        alt="linkedin-img"
                      />
                    </Link>
                  </div>
                  <p className="w-full relative float-left text-[#e1e5ef] text-sm tracking-[.2px] text-center opacity-60 font-normal leading-4">
                    <span>© 2023 Azure, All Rights Reserved.</span>
                  </p>
                </div>
              </div>
            </div>
          </footer>
        </div>
      </div>
    </>
  );
};

export default Consultant;
