/* global webkitSpeechRecognition */
import { useEffect, useRef, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getCourses, getInterviewQuestion } from "services/api";

const Interviewprep = () => {
  const [courses, setCourses] = useState([]);
  const [selectedCourseId, setSelectedCourseId] = useState("");
  const [interviewQuestionsForCourse, setInterviewQuestionsForCourse] =
    useState([]);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [recording, setRecording] = useState(false);
  const [transcript, setTranscript] = useState("");
  const [lastProcessedIndex, setLastProcessedIndex] = useState(0);
  const [interviewStarted, setInterviewStarted] = useState(false);
  const [screenBlob, setScreenBlob] = useState(null);
  const videoRef = useRef(null);
  const screenMediaRecorderRef = useRef(null);
  const screenChunksRef = useRef([]);
  const screenStreamRef = useRef(null);
  const cameraStreamRef = useRef(null);

  const recognitionRef = useRef(null); // Reference to SpeechRecognition instance

  const fetchCourses = async () => {
    try {
      const response = await getCourses();
      if (response) {
        setCourses(response?.data.courses);
        setSelectedCourseId(response?.data.courses[0]?.id || ""); // Set initial course
      } else {
        toast.error("Something Went Wrong, Please Try Again.", {
          autoClose: 3000,
        });
      }
    } catch (error) {
      toast.error("Something Went Wrong, Please Try Again.", {
        autoClose: 3000,
      });
    }
  };

  const fetchInterviewQuestions = async (courseId) => {
    try {
      const response = await getInterviewQuestion();
      if (response) {
        const filteredQuestions = response?.interview_questions.filter(
          (question) => question.training_course_id === parseInt(courseId)
        );
        setInterviewQuestionsForCourse(filteredQuestions);
      } else {
        toast.error("Failed to fetch interview questions.", {
          autoClose: 3000,
        });
      }
    } catch (error) {
      toast.error("Something Went Wrong, Please Try Again.", {
        autoClose: 3000,
      });
    }
  };

  useEffect(() => {
    fetchCourses();
  }, []);

  useEffect(() => {
    if (selectedCourseId) {
      fetchInterviewQuestions(selectedCourseId);
      setCurrentQuestion(0);
    }
  }, [selectedCourseId]);

  useEffect(() => {
    if ("webkitSpeechRecognition" in window) {
      recognitionRef.current = new webkitSpeechRecognition();
      recognitionRef.current.lang = "en-US";
      recognitionRef.current.interimResults = true;
      recognitionRef.current.continuous = true;

      recognitionRef.current.onresult = (event) => {
        let finalTranscript = "";
      
        for (let i = event.resultIndex; i < event.results.length; i++) {
          const transcriptPart = event.results[i][0].transcript;
      
          if (event.results[i].isFinal) {
            finalTranscript += transcriptPart;
          }
        }
      
        setTranscript((prevTranscript) => prevTranscript + finalTranscript);
      };
      

      recognitionRef.current.onerror = (event) => {
        toast.error("Speech recognition error: " + event.error, {
          autoClose: 3000,
        });
      };
    } else {
      toast.error("Speech recognition not supported in this browser.", {
        autoClose: 3000,
      });
    }
  }, []);

  const startRecording = async () => {
    try {
      toast.info(
        "Please select the specific window or tab you want to record.",
        { autoClose: 5000 }
      );

      const screenStream = await navigator.mediaDevices.getDisplayMedia({
        video: { cursor: "always" },
      });
      const cameraStream = await navigator.mediaDevices.getUserMedia({
        video: true,
        audio: true,
      });

      videoRef.current.srcObject = cameraStream;
      videoRef.current.play();

      const combinedStream = new MediaStream();
      screenStream
        .getTracks()
        .forEach((track) => combinedStream.addTrack(track));
      cameraStream
        .getTracks()
        .forEach((track) => combinedStream.addTrack(track));

      screenStreamRef.current = screenStream;
      cameraStreamRef.current = cameraStream;

      screenMediaRecorderRef.current = new MediaRecorder(combinedStream);
      screenMediaRecorderRef.current.ondataavailable =
        handleScreenDataAvailable;
      screenMediaRecorderRef.current.onstop = () => {
        console.log("Recording stopped");
      };

      screenMediaRecorderRef.current.start();

      setRecording(true);
      recognitionRef.current.start(); 
    } catch (error) {
      toast.error("Something Went Wrong, Please Try Again.", {
        autoClose: 3000,
      });
      window.location.reload()
    }
  };

  const stopRecording = () => {
    return new Promise((resolve) => {
      if (screenMediaRecorderRef.current) {
        screenMediaRecorderRef.current.onstop = () => {
          resolve();
        };
        screenMediaRecorderRef.current.stop();
      } else {
        resolve();
      }

      if (recognitionRef.current) {
        recognitionRef.current.stop(); 
      }
    });
  };

  const stopStreams = () => {
    if (screenStreamRef.current) {
      screenStreamRef.current.getTracks().forEach((track) => track.stop());
    }
    if (cameraStreamRef.current) {
      cameraStreamRef.current.getTracks().forEach((track) => track.stop());
    }
  };

  const handleScreenDataAvailable = (event) => {
    if (event.data.size > 0) {
      console.log("Data available:", event.data.size);
      screenChunksRef.current.push(event.data);
    }
  };

  const handleNextQuestion = () => {
    setTranscript("")
    if (currentQuestion < interviewQuestionsForCourse?.length - 1) {
      setCurrentQuestion(currentQuestion + 1);
      if (!recording) {
        startRecording(); 
      }
    }
  };

  const handleCourseChange = (e) => {
    setSelectedCourseId(e.target.value);
  };

  const handleStartInterview = () => {
    setInterviewStarted(true);
    startRecording();
  };

  const handleSubmitInterview = async () => {
    setTranscript("")
    setCurrentQuestion(0)
    setInterviewStarted(false);

    await stopRecording();
    stopStreams();

    if (screenChunksRef.current.length === 0) {
      toast.error(
        "No recording data available. Please ensure recording was started properly.",
        { autoClose: 3000 }
      );
      return;
    }

    const screenBlob = new Blob(screenChunksRef.current, {
      type: "video/webm",
    });
    const screenDownloadUrl = URL.createObjectURL(screenBlob);
    const aScreen = document.createElement("a");
    aScreen.href = screenDownloadUrl;
    aScreen.download = "screen_recording.webm";
    document.body.appendChild(aScreen);
    aScreen.click();
    document.body.removeChild(aScreen);

    URL.revokeObjectURL(screenDownloadUrl);
    screenChunksRef.current = [];

    toast.success("Interview successfully downloaded in your system!", { autoClose: 3000 });

  };

  return (
    <>
      <ToastContainer />
      <div className="mx-12 lg:mt-28 md:mt-20 max-sm:mt-20 sm:mt-20">
        <div className="flex flex-wrap w-full gap-6 mb-4">
          <div className="w-full xl:w-[62%]">
            <div className="bg-[#1A1C1E] rounded-lg border border-[#37383A]">
              <div className="flex items-center justify-between p-5">
                <label className="text-[#BDBEBE]">
                  Select Course: {"     "}
                  <select
                    value={selectedCourseId}
                    onChange={handleCourseChange}
                    disabled={interviewStarted}
                    className="px-2 py-1 rounded"
                    style={{
                      backgroundColor: "#37383A",
                      color: "#BDBEBE",
                      borderColor: "#BDBEBE",
                      fontFamily: "Poppins, sans-serif",
                    }}
                  >
                    {courses.map((course) => (
                      <option key={course.id} value={course.id}>
                        {course.course_name}
                      </option>
                    ))}
                  </select>
                </label>
                <span className="text-xl text-[#BDBEBE]">
                  {interviewQuestionsForCourse?.length > 0
                    ? `${currentQuestion + 1}/${
                        interviewQuestionsForCourse?.length
                      }`
                    : "0/0"}
                </span>
              </div>

              <div className="p-5">
                {!interviewStarted ? (
                  <>
                    {interviewQuestionsForCourse?.length > 0 ? (
                      <button
                        onClick={handleStartInterview}
                        className="w-full rounded-[4px] cursor-pointer bg-[#5b52e7] p-3 text-white"
                      >
                        Start Interview
                      </button>
                    ) : (
                      <p className="text-[#BDBEBE]">
                        No questions available for this course.
                      </p>
                    )}
                  </>
                ) : (
                  <>
                    <h4 className="text-xl font-bold text-white">
                      {currentQuestion + 1}.{" "}
                      {interviewQuestionsForCourse[currentQuestion]?.question}
                    </h4>
                    <div className="relative w-full pt-[56%] my-4">
                      <video
                        ref={videoRef}
                        className="absolute top-0 left-0 w-full h-full rounded-xl"
                        muted
                        autoPlay
                      ></video>
                    </div>
                    <div className="flex gap-3">
                      {currentQuestion <
                      interviewQuestionsForCourse?.length - 1 ? (
                        <button
                          className="rounded-[4px] cursor-pointer bg-[#5b52e7] p-3 text-white"
                          onClick={handleNextQuestion}
                        >
                          Next
                        </button>
                      ) : (
                        <button
                          className="rounded-[4px] cursor-pointer bg-[#5b52e7] p-3 text-white"
                          onClick={handleSubmitInterview}
                        >
                          Submit
                        </button>
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>

          <div className="w-full xl:w-[35%]">
            <div className="bg-[#1A1C1E] rounded-lg border border-[#37383A] p-5">
              <h4 className="text-xl font-bold text-white">Transcript</h4>
              <textarea
                rows="10"
                value={transcript}
                readOnly
                onChange={(e) => setTranscript(e.target.value)}
                placeholder="Interview transcript will appear here..."
                className="w-full mt-2 p-3 bg-[#37383A] text-white rounded-lg"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Interviewprep;
