/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/rules-of-hooks */
import { Button } from "@mui/material";
import { useAuthInfo, useRedirectFunctions } from "@propelauth/react";
import { useStateContext } from "contexts/ContextProvider";
import useAuth from "hooks/useAuth";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "styles/Consultant.css";
import logo from "../../data/logo-light.svg";


const Header = ({ sidebarOpen, setSidebarOpen }) => {
  // const { auth } = useAuth();
  const { redirectToLoginPage, redirectToSignupPage, redirectToAccountPage } =
    useRedirectFunctions();
  const authInfo = useAuthInfo(); // Assuming useAuthInfo() provides the JSON response

  const navigate = useNavigate();
  const {
    currentColor,
    activeHeader,
    setActiveHeader,
    handleClick,
    isClicked,
    setScreenSize,
    screenSize,
  } = useStateContext();
  const [color, setColor] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      if (offset > 0) {
        setColor(true);
      } else {
        setColor(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  useEffect(() => {
    if (screenSize <= 900) {
      setActiveHeader(false);
    } else {
      setActiveHeader(true);
    }
  }, [screenSize]);

  const handleActiveMenu = () => setActiveHeader(!activeHeader);
  const handleLoginClick = () => {
    

    if (authInfo && authInfo.isLoggedIn) {
      const userOrgInfo = authInfo.userClass.orgIdToUserOrgInfo;
      const orgIds = Object.keys(userOrgInfo);

      for (const orgId of orgIds) {
        const userAssignedRole = userOrgInfo[orgId].userAssignedRole;
      
      }
    }


    redirectToLoginPage({
      postLoginRedirectUrl: "http://localhost:3000/",
    });
  };
  return (
    <header
      className={`flex items-center py-3 px-5 text-semibold bg-[#1A1C1E] z-50 fixed w-full top-0`}
    >
      <div className={` w-full pr-8 ${sidebarOpen ? "pr-80" : ""}`}>
        <div className="flex justify-between row">
          <div className="grid items-center justify-center grid-cols-1 text-center pl-44">
            <Link to="/" className="logo">
                            <img src={logo} alt="logo"></img>
            </Link>
          </div>
          <div className="grid items-center grid-cols-2">
            <Button
              style={{
                color: "#5B53E7",
                background: "white",
                borderRadius: "12px",
                textTransform: "capitalize",
              }}
              className="signin-btn"
              onClick={() =>
                redirectToLoginPage({
                  // postLoginRedirectUrl: "http://localhost:3000/",
                  postLoginRedirectUrl: "https://training.hcomb.ai/",
                })
              }
            >
              Log In
            </Button>
            {/* <Link to="" className="signup-btn">
              Sign Up
            </Link> */}
          </div>
        </div>
      </div>
    </header>
  );
};
export default Header;
